<template>
    <div class="visitantes pt-4">
        <div class="d-middle-bt gap-3 px-5 mb-3" style="min-width: 771px;">
            <p class="text-black f-20 f-medium">Historial de visitas</p>
            <div class="d-middle gap-3">
                <template v-if="fechaInicio || fechaFin">
                    <span v-if="fechaInicio" class="text-86 f-16">{{ fechaInicio.nombre | helper-fecha('DD MMM YYYY') }}</span>
                    <span v-if="fechaFin" class="text-86 f-16">{{ fechaFin.nombre | helper-fecha('DD MMM YYYY') }}</span>
                </template>
                <template v-else>
                    <span class="text-86 f-16">{{ hoy | helper-fecha('DD MMM YYYY') }}</span>
                </template>
                <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                    <template #content>
                        <template v-if="filtro.length > 0">
                            <div class="d-flex flex-column gap-2 w-177px">
                                <p class="d-flex justify-content-end f-11 text-blue cr-pointer" @click="limpiarFiltro">Borrar filtro</p>
                                <div v-for="item, index in filtro" :key="index">
                                    <p class="f-medium text-86 f-12 border-bottom border-light mb-1">{{ item.titulo }}</p>
                                    <p class="f-light text-86 f-12">{{ item.nombre }}</p>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            Filtrar
                        </template>
                    </template>
                    <button-filter :filtering="filtro.length > 0" @click="abrirModalFiltroVisitante" />
                </el-tooltip>
                <div>
                    <export-excel
                        :data="exportarVisitas"
                        :fields="excel.campos"
                        :before-generate="() => excel.loading = true"
                        :before-finish="() => excel.loading = false"
                        worksheet="visitantes"
                        name="visitantesHistorial.xls"
                        >
                            <button v-loading="excel.loading" class="d-middle border bg-f5 br-8 h-32px f-300 f-14 mx-2 pr-3">
                                <i class="icon-download f-20" />
                                <span>Exportar</span>
                            </button>
                        </export-excel>
                </div>
                <div class="w-300px">
                    <form @submit.prevent="obtenerHistorialVisitantes(null)">
                        <el-input v-model="search" placeholder="Buscar por número de cédula" size="small">
                            <template slot="prefix">
                                <i class="icon-search text-86 f-20 pr-2 cr-pointer9" @click="obtenerHistorialVisitantes" />
                            </template>
                        </el-input>
                    </form>
                </div>
            </div>
        </div>
        <div class="row overflow-auto custom-scroll" style="height:calc(100vh - 180px)">
            <el-table
            :data="historial"
            height="calc(100vh - 180px)"
            style="width: 100%"
            @row-click="abrirDetalleVisitante"
            >
                <el-table-column sortable label="Nombre" min-width="250">
                    <template slot-scope="scope">
                        <div class="d-middle gap-3">
                            <img v-if="scope.row.foto" :src="scope.row.foto" alt="visitante-img" class="wh-32 rounded-circle obj-cover" @error="scope.row.foto = '/img/no-imagen/admin.svg'" />
                            <img v-else src="/img/no-imagen/admin.svg" alt="visitante-img" class="wh-32 rounded-circle obj-cover" @error="scope.row.foto = '/img/no-imagen/admin.svg'" />
                            <p>{{ scope.row.nombre }} {{ scope.row.apellido }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column sortable label="Cedula" prop="cedula" width="150" />
                <el-table-column sortable label="Grupo" prop="grupo" width="180" align="left" />
                <el-table-column sortable label="Vivienda" prop="vivienda" width="200" align="left" />
                <el-table-column sortable label="Tipo de visita" width="200">
                    <template slot-scope="scope">
                        <div class="d-middle gap-3">
                            <i :class="tipoVisitante(scope.row.tipo)" />
                            <p>{{ tipoVisitanteNombre(scope.row.tipo) }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column sortable label="Fecha" width="180">
                    <template slot-scope="scope">
                        <p>{{ scope.row.fechaIngreso | helper-fecha('DD MMM YYYY') }}</p>
                    </template>
                </el-table-column>
                <el-table-column sortable label="Hora" width="150" align="left">
                    <template slot-scope="scope">
                        <p class="text-lowercase">{{ scope.row.horaIngreso }}</p>
                    </template>
                </el-table-column>
                <el-table-column sortable label="Ingresado por" prop="vigilante" width="200" />
                <div slot="empty" class="row">
                    <div class="col-12 text-center">
                        <template v-if="hasSearched">
                            <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120" />
                            <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No se han encontrado visitas</p>
                        </template>
                        <template v-else>
                            <img src="/img/generales/sin_visitantes.svg" alt="" width="120" height="120" />
                            <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No se han registrado visitas el día de hoy</p>
                        </template>
                    </div>
                </div>
                <infinite-loading
                slot="append"
                force-use-infinite-wrapper=".el-table__body-wrapper"
                @infinite="obtenerHistorialVisitantes"
                />
            </el-table>
        </div>
        <modal-detalle-visitante
        ref="modalDetalleVisitante"
        :imagen="visitante.foto"
        :nombre="`${visitante.nombre} ${visitante.apellido}`"
        :documento="visitante.cedula"
        :total-ingresos="visitante.totalIngresos"
        :ultimo-ingreso="visitante.ultimoIngreso"
        >
            <template slot="body">
                <div class="d-flex gap-2 mb-3 d-middle">
                    <div class="wh-32 rounded-circle d-middle-center bg-azul-vivienda">
                        <i class="icon-house-outline text-white f-20" />
                    </div>
                    <div>
                        <p class="f-15 text-black">Vivienda</p>
                        <p class="f-15 f-light text-86 ucfirst">{{ visitante.vivienda }}</p>
                    </div>
                </div>
                <div class="d-flex gap-2 mb-3 d-middle">
                    <div class="wh-32 rounded-circle d-middle-center bg-purpura">
                        <i class="icon-calendar text-white f-20" />
                    </div>
                    <div>
                        <p class="f-15 text-black ucfirst">{{ visitante.fechaIngreso | helper-fecha('dddd, DD MMM YYYY') }}</p>
                        <p class="f-15 f-light text-86 ucfirst text-lowercase">{{ visitante.horaIngreso }}</p>
                    </div>
                </div>
                <div class="d-flex gap-2 mb-3 d-middle">
                    <div class="wh-32 rounded-circle d-middle-center bg-azul-vivienda">
                        <i :class="tipoVisitante(visitante.tipo)" class="text-white" style=" left: -3px;" />
                    </div>
                    <div v-if="visitante.tipo != 1">
                        <p v-if="visitante.tipo === 2" class="f-15 text-black"> Carro </p>
                        <p v-else class="f-15 text-black"> Moto </p>
                        <p class="f-15 f-light text-86 ucfirst">{{ visitante.marca }},{{ visitante.placa }},{{ visitante.color }}</p>
                    </div>
                    <div v-else>
                        <p class="f-15 text-black"> Caminando </p>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="f-14 f-medium text-black">Observación</label>
                    <p class="f-light f-14 text-black">{{ visitante.observacion }}</p>
                </div>
                <div class="mb-3">
                    <label class="f-14 f-medium text-black">Ingresado por</label>
                    <p class="f-light f-14 text-black">{{ visitante.vigilante }}</p>
                </div>
            </template>
        </modal-detalle-visitante>
        <modal-filtro-visitante
        ref="modalFiltroVisitante"
        :agrupaciones="agrupaciones"
        :viviendas="viviendas"
        mostrar-filtro-vivienda
        mostrar-filtro-fecha
        mostrar-filtro-tipo
        @agrupacionSeleccionada="obtenerViviendasPorIdAgrupacion"
        @filtrar="aplicarFiltro"
        @limpiar="obtenerHistorialVisitantes(null)"
        />
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import modalDetalleVisitante from './partials/modalDetalleVisitante.vue';
import modalFiltroVisitante from './partials/modalFiltroVisitante.vue';
import Viviendas from '~/services/viviendas'
import excel from 'vue-excel-export'
import Vue from 'vue'

import {
    ObtenerHistorialVisitantes,
    ObtenerHistorialVisitante,
    exportarExcelVisitas
} from '~/services/visitantes'

Vue.use(excel)
export default {
    components: {
        InfiniteLoading,
        modalDetalleVisitante,
        modalFiltroVisitante,
    },
    data(){
        return {
            hoy: new Date(),
            search: '',
            hasSearched: false,
            loading: false,
            state: {},
            page: 1,
            filtro: [],
            agrupaciones: [],
            viviendas: [],
            historial: [],
            visitante: {
                viviendas: []
            },
            tipoVisita: [],
            exportarVisitas: [],
            excel: {
                loading: false,
                campos: {
                    'Nombre': 'visitante',
                    'Cedula': 'cedula',
                    'Vivienda Ingreso': 'vivienda',
                    'Tipo Visita': 'tipo_visita',
                    'Fecha Ingreso': 'fechaIngreso',
                    'Hora Ingreso': 'horaIngreso',
                    'Vigilante': 'vigilante'
                },
            },
        }
    },
    computed: {
        fechaInicio(){
            return this.filtro.find(el => el.id == 3)
        },
        fechaFin(){
            return this.filtro.find(el => el.id == 4)
        }
    },
    async mounted(){
        this.obtenerAgrupaciones();
    },
    methods: {
        async aplicarFiltro(filtrado){
            this.filtro = filtrado.datos;
            this.tipoVisita = filtrado.tipo;
            await this.obtenerHistorialVisitantes();
            this.$refs.modalFiltroVisitante.toggle();
        },
        async obtenerAgrupaciones(){
            try {
                const { data } = await Viviendas.getViviendas()
                this.agrupaciones = data.data;
            } catch (error){
                console.error(error);
            }
        },
        async obtenerViviendasPorIdAgrupacion(id_agrupacion = null){
            try {
                const params = { id_padre: id_agrupacion }
                const { data } = await Viviendas.getViviendasHijas(params)
                if(data.success) this.viviendas = data.data;
            } catch (error){
                console.error(error);
            }
        },
        async obtenerHistorialVisitante(id){
            try {
                const params = {
                    idHistorial: id,
                }
                const { data } = await ObtenerHistorialVisitante(params)
                if(data.success) this.visitante = data.data;
            } catch (error){
                console.error(error);
            }
        },
        async obtenerHistorialVisitantes($state){
            try {
                this.loading = true

                if($state == null){
                    this.state.reset();
                    this.historial = [];
                    this.page = 1;
                    return
                }

                this.state = $state

                const params={
                    cedula: this.search,
                    idAgrupacion: this.$refs.modalFiltroVisitante.agrupacion_id,
                    idVivienda: this.$refs.modalFiltroVisitante.vivienda_id,
                    fechaInicio: this.$refs.modalFiltroVisitante.fecha_inicio_formated,
                    fechaFin: this.$refs.modalFiltroVisitante.fecha_fin_formated,
                    tipo: this.tipoVisita,
                    page: this.page
                }

                const {data} = await ObtenerHistorialVisitantes(params);

                if(data.data.data.length){
                    this.page += 1;
                    this.historial.push(...data.data.data)
                    this.state && this.state.loaded();
                }else{
                    this.state && this.state.complete();
                }

                await this.historialVisitas();

            } catch (e){
                this.errorCatch(e)
            } finally {
                this.loading = false
            }
        },
        async abrirDetalleVisitante(e){
            const { id } = e;
            await this.obtenerHistorialVisitante(id)
            this.$refs.modalDetalleVisitante.toggle(id)
        },
        buscarFiltroPorId(id){
            // console.log(id);
            const filtro = this.filtro.filter(el => el.id === id)
            // console.log(filtro);
            if(filtro) return filtro.nombre
        },
        limpiarFiltro(){
            this.filtro = []
            this.$refs.modalFiltroVisitante.limpiarFiltro()
        },
        abrirModalFiltroVisitante(){
            this.$refs.modalFiltroVisitante.toggle();
        },
        tipoVisitante(tipo){
            switch (tipo){
            case 1:
                return 'icon-walk f-22'
            case 2:
                return 'icon-car f-22'
            case 3:
                return 'icon-motorbike position-relative f-15'
            }
        },
        tipoVisitanteNombre(tipo){
            switch (tipo){
            case 1:
                return 'Caminando'
            case 2:
                return 'Carro'
            case 3:
                return " Moto"
            }
        },
        async historialVisitas(){
            try {
                const params = {
                    idAgrupacion: this.$refs.modalFiltroVisitante.agrupacion_id,
                    idVivienda: this.$refs.modalFiltroVisitante.vivienda_id,
                    fechaInicio: this.$refs.modalFiltroVisitante.fecha_inicio_formated,
                    fechaFin: this.$refs.modalFiltroVisitante.fecha_fin_formated,
                    tipo: this.tipoVisita
                }

                const { data } = await exportarExcelVisitas(params);
                this.exportarVisitas = data;
            } catch (e){
                this.errorCatch(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>